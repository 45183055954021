var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{attrs:{"min-height":"250"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.title || 'Profile')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-label',{attrs:{"item-field-name":"Username"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Username","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Name"}}),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Name","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Email"}}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Email","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Password"}}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Password","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.pw),callback:function ($$v) {_vm.$set(_vm.model, "pw", $$v)},expression:"model.pw"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Role"}}),_c('ValidationProvider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Role","items":_vm.roleList,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.role),callback:function ($$v) {_vm.$set(_vm.model, "role", $$v)},expression:"model.role"}})]}}],null,true)})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Team"}}),_c('ValidationProvider',{attrs:{"name":"Team","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Team","items":_vm.teamList,"error":!!errors.length,"error-messages":errors,"item-value":"_id","item-text":"label"},model:{value:(_vm.model.team),callback:function ($$v) {_vm.$set(_vm.model, "team", $$v)},expression:"model.team"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('work-period-card',{model:{value:(_vm.model.workTimePeriod),callback:function ($$v) {_vm.$set(_vm.model, "workTimePeriod", $$v)},expression:"model.workTimePeriod"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.doClose()}}},[_vm._v("Close")]),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":!valid},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":!valid},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }