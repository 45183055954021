<template>
  <v-container class="pa-3">
    <div class="mainTitle">Notification</div>
    <div v-if="isAdmin || isManager">
      <ValidationObserver ref="form" v-slot="{ valid }">
        <template>
          <v-row>
            <v-col>
              <v-checkbox v-model="all" label="All" />
            </v-col>
            <v-col v-if="isAdmin">
              <v-checkbox v-model="allManagers" label="All Managers" />
            </v-col>
            <v-col v-if="isAdmin">
              <v-checkbox v-model="allAgents" label="All Agents" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="To"
                chips
                small-chips
                multiple
                v-model="recipients"
                :items="autocompleteUsers"
                item-text="name"
                item-value="_id"
                :disabled="allManagers || allAgents"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <ValidationProvider v-slot="{ errors }" name="content" rules="required">
          <v-textarea
            label="Send Notification"
            v-model="content"
            :error-messages="errors"
          ></v-textarea>
        </ValidationProvider>
        <div style="display: flex">
          <v-spacer />
          <v-btn color="success" class="mb-3" @click="sentNotificationToAll" :disabled="!valid">
            Send
          </v-btn>
        </div>
        <v-divider class="mb-2" />
      </ValidationObserver>
    </div>
    <div>
      <v-card>
        <v-card-text>
          <v-row no-gutters class="mb-3 itemFieldName">
            <v-col class="subTitle" cols="9"> Notification Details</v-col>
            <v-col class="subTitle" cols="3"> Date </v-col>
          </v-row>
          <v-list>
            <v-list-item v-for="(item, i) in list.docs" :key="i">
              <v-row
                class="mb-3 pb-2 text"
                no-gutters
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              >
                <v-col cols="9">{{ item.content }}</v-col>
                <v-col cols="3">{{ item.created_at | moment('YYYY-MM-DD HH:mm') }}</v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-pagination class="mt-4" v-model="pageVal" :length="list.pages || 1" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { getId } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';
import {
  listNotification,
  notificationMarkAsRead,
  sendNotificationToAll,
} from '@/api/notification';
import api from '@/api';
import { crudList } from '@/api/crud';

export default {
  name: 'notification',

  data() {
    return {
      content: null,
      list: { docs: [], offset: 0, page: 1, pages: 1, limit: 9 },
      recipients: [],
      autocompleteUsers: [],
      page: 1,
      allManagers: false,
      allAgents: false,
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapActions('alert', ['updateAlertMessage']),
    ...mapGetters('auth', ['isAdmin', 'isManager', 'isAgent', 'profileId']),
    all: {
      get() {
        return this.allManagers && this.allAgents;
      },
      set(isTrue) {
        if (isTrue) {
          this.allManagers = true;
          this.allAgents = true;
        } else {
          this.allManagers = false;
          this.allAgents = false;
        }
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
  methods: {
    alertMessage(type, err) {
      if (type === 'success') {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Sent',
          type: 'success',
          color: 'success',
        });
      } else {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async showTeamList() {
      let res = await crudList(api, 'im/admin/profiles', {
        filter: { role: ['manager', 'agent'] },
        limit: 99,
        populate: 'team',
      });
      this.autocompleteUsers = res.docs;
    },
    async sentNotificationToAll() {
      try {
        if (this.all) {
          await sendNotificationToAll(api, this.content, 'all');
        } else if (this.allManagers) {
          await sendNotificationToAll(api, this.content, 'managers');
        } else if (this.allAgents) {
          await sendNotificationToAll(api, this.content, 'agents');
        } else {
          await sendNotificationToAll(api, this.content, this.recipients);
        }
        this.alertMessage('success');
      } catch (err) {
        this.alertMessage('', err);
      }
    },
    async reload() {
      const temp = await listNotification(api, {
        sort: { created_at: -1 },
        page: this.page,
      });
      this.list = temp || [];
      const ids = this.list.docs.map(x => getId(x));
      if (ids.length) {
        await notificationMarkAsRead(api, ids);
      }
      await this.$store.dispatch('notification/readNotify');
      if (this.isAdmin || this.isManager) {
        await this.showTeamList();
      }
    },
  },
};
</script>

<style scoped></style>
