<template>
  <v-card height="100%" min-height="150">
    <v-card-title> Name: {{ content.username }} </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'loginAccountCard',
  props: {
    content: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped></style>
