<template>
  <ListView
    title="Profiles"
    @reload="reload"
    @create="actionCreate"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :enableCreate="selectedRole.toLowerCase() !== 'client'"
  >
    <template v-slot:card="{ cardContent }">
      <Card :content="cardContent" class="clickable" @click.native="actionDetails(cardContent)" />
    </template>
    <IdentityEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </ListView>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import Card from '@/components/Identity/IdentityCard';
import IdentityEditDialog from '@/components/Identity/IdentityEditDialog';
import { mapGetters } from 'vuex';

export default {
  name: 'profiles',
  props: {
    selectedRole: {},
  },
  mixins: [listViewPageMixin],
  components: { IdentityEditDialog, Card },
  computed: {
    ...mapGetters('auth', ['role']),
    restfulURL: () => 'im/admin/Profiles',
    filter() {
      return this.selectedRole ? { role: this.selectedRole.toLowerCase() } : null;
    },
    mergeParams() {
      return {
        populate: ['team'],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.selectedItem ::v-deep .v-select__selection--comma {
  margin-bottom: -25px;
}
.role {
  top: 10px;
  position: absolute;
  left: 24px;
  color: var(--v-success-base);
}
</style>
