<template>
  <ListView
    title="LoginAccounts"
    @reload="reload"
    @create="actionCreate"
    :search.sync="search"
    :data="data"
    :page.sync="page"
  >
    <template v-slot:card="{ cardContent }">
      <Card :content="cardContent" />
    </template>
  </ListView>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import Card from '@/components/loginAccount/loginAccountCard';

export default {
  name: 'LoginAccounts',
  mixins: [listViewPageMixin],
  components: { Card },
  computed: {
    restfulURL: () => 'users',
  },
};
</script>

<style scoped></style>
