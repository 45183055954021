<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="d-flex">
          <h2 class="mainTitle">{{ title }}</h2>
          <v-spacer />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="flex: 2">
        <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="searchVal" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-3 itemFieldName">
      <v-col cols="8"> Activity Title </v-col>
      <v-col cols="2">Status</v-col>
      <v-col cols="2"> Date </v-col>
    </v-row>
    <v-row
      class="mb-3 pb-2 text"
      no-gutters
      v-for="(n, i) in list.docs"
      :key="n._id"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); height: 60px"
    >
      <v-col cols="8" @click="goTo(n.chat)" class="clickable"
        >{{ n.content }}<template v-if="n.isSystem">: {{ n.chat }} </template>
      </v-col>
      <v-col cols="2">{{ n.status || 'Empty' }}</v-col>
      <v-col cols="2">{{ n.updated_at | moment('YYYY-MM-DD HH:mm') }}</v-col>
    </v-row>
    <v-pagination class="mt-4" v-model="pageVal" :length="list.pages || 1" />
    <slot></slot>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudList } from '@/api/crud';

export default {
  name: 'ActivityManagement',
  data() {
    return {
      title: 'Activity Management',
      list: { docs: [], offset: 0, page: 1, pages: 1, limit: 9 },
      search: '',
      page: 1,
    };
  },
  mounted() {
    this.reload();
  },
  methods: {
    goTo(id) {
      this.$router.push({ name: 'Chat', params: { id } }).catch(() => {});
    },
    async reload() {
      try {
        this.list = await crudList(api, 'im/activities', {
          sort: { created_at: -1 },
          populate: ['created_by'],
          page: this.page,
          search: this.search,
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
  computed: {
    searchVal: {
      get() {
        return this.search;
      },
      set(val) {
        this.search = val;
        this.page = 1;
        this.reload();
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
};
</script>
