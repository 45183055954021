<template>
  <v-container>
    Activity Details
    <v-row>
      <v-col>
        <v-form ref="form" v-model="valid"> </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ActivityDetails',
  props: {
    id: {},
  },
  data() {
    return {
      valid: false,
      model: {},
    };
  },
  methods: {
    async reload() {},
  },

  beforeMount() {
    this.reload();
  },
};
</script>

<style scoped></style>
