<template>
  <v-container fluid>
    <div class="mainTitle">Dashboard</div>
    <div class="d-flex align-end">
      <v-row>
        <v-col class="ml-auto" cols="1">
          <v-btn @click="reload">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-text-field
            dense
            outlined
            type="number"
            min="2000"
            label="Year"
            v-model="year"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="month"
            outlined
            dense
            offset-y
            transition="scale-transition"
            label="Month"
            :items="months"
            item-text="month"
            item-value="num"
          />
        </v-col>
      </v-row>
    </div>
    <div v-for="(item, i) in data" :key="i">
      <v-row>
        <v-col>
          <span class="mainTitle">{{ item.team && item.team.label }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-card-title> Total Sent Message: {{ item.out }}</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title> Total Received Message: {{ item.in }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { msgSentStatistics } from '@/api/admin';
import api from '@/api';
import moment from 'moment';

export default {
  name: 'dashboard',
  data() {
    return {
      year: moment().year(),
      month: moment().month() + 1,
      data: [],
      months: [
        { month: 'January', num: 1 },
        { month: 'February', num: 2 },
        { month: 'March', num: 3 },
        { month: 'April', num: 4 },
        { month: 'May', num: 5 },
        { month: 'June', num: 6 },
        { month: 'July', num: 7 },
        { month: 'August', num: 8 },
        { month: 'September', num: 9 },
        { month: 'October', num: 10 },
        { month: 'November', num: 11 },
        { month: 'December', num: 12 },
      ],
    };
  },

  watch: {
    filter: {
      immediate: true,
      handler() {
        this.reload();
      },
    },
  },
  computed: {
    filter() {
      return JSON.stringify([this.year, this.month]);
    },
  },
  methods: {
    async reload() {
      let filter;
      if (this.year) {
        filter = Object.assign({}, { year: parseInt(this.year) }, filter);
      }
      if (this.month) {
        filter = Object.assign({}, { month: this.month }, filter);
      }
      try {
        this.data = (await msgSentStatistics(api, { filter })).data;
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
